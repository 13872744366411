import React, { useEffect, useState, useCallback } from "react";
import Card from "components/card";
import { BASE_URL_TWO, userStaticsHeader } from "Constant/Constant";
import axios from "axios";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import moment from "moment";
import { CSVLink } from "react-csv";

const MobileAppUsers = () => {
  const [mobileData, setMobileData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);
  const [csvData, setCsvData] = useState([]);
  const [csvLoading, setCsvLoading] = useState(false);
  const limit = 10;

  const fetchUsers = async (page: any) => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${BASE_URL_TWO}api/users/appUsers?page=${page}&limit=${limit}`
      );
      setMobileData(response.data.users);
      setTotalPages(response.data.totalPages);
      setTotalUsers(response.data.totalUsers);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching users:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers(currentPage);
  }, [currentPage]);

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const getMobileAppUsers = useCallback(
    async (event: any, done: any) => {
      if (!csvLoading) {
        try {
          setCsvLoading(true);
          const apiUrl = `${BASE_URL_TWO}webGetMobileUsers`;

          const response = await axios.get(apiUrl, {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          });

          if (response.data) {
            setCsvData(response.data);
            setCsvLoading(false);
            done(true);
          } else {
            throw new Error("No data received");
          }
        } catch (error) {
          console.error("Error fetching data:", error);
          setCsvLoading(false);
          alert("Failed to fetch data for CSV export. Please try again later.");
          done(false);
        }
      }
    },
    [csvLoading]
  );

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxPage = totalPages;

    // Handle first 3 pages, last 3 pages and the current page
    for (let i = 1; i <= maxPage; i++) {
      if (
        i <= 3 ||
        i >= maxPage - 2 ||
        (i >= currentPage - 1 && i <= currentPage + 1)
      ) {
        pageNumbers.push(i);
      }
    }

    return pageNumbers.map((page, index) => (
      <button
        key={index}
        onClick={() => setCurrentPage(page)}
        className={`relative inline-flex items-center px-4 py-2 text-sm font-medium ${
          currentPage === page
            ? "z-10 bg-teal-600 text-white"
            : "text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20"
        }`}
      >
        {page}
      </button>
    ));
  };

  return (
    <Card extra={"w-full h-full sm:overflow-auto"}>
      <div className="px-4 sm:px-6 lg:px-8">
        <header className="relative flex items-center justify-between pt-4">
          <div className="text-xl font-bold text-navy-700 dark:text-white">
            {`Mobile App Users (${totalUsers})`}
          </div>
          <div>
            {csvLoading ? (
              <h1>Loading...</h1>
            ) : (
              <CSVLink
                data={csvData}
                headers={userStaticsHeader}
                asyncOnClick={true}
                onClick={getMobileAppUsers}
                className="block rounded-md bg-teal-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
              >
                Export as CSV
              </CSVLink>
            )}
          </div>
        </header>

        <div className="mt-8 flow-root pb-4">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <div className="ring-black/5 overflow-hidden shadow ring-1 sm:rounded-lg">
                {loading ? (
                  <div className="p-6 text-center">Loading...</div>
                ) : (
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        >
                          Name
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          User since
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Mobile Number
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Balance
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Card Number
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {mobileData.map((user) => (
                        <tr key={user.id}>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 dark:!text-white sm:pl-6">
                            <span className="inline-flex h-10 w-10 items-center justify-center rounded-full bg-teal-600 text-white">
                              <span className="font-medium leading-none text-white">
                                {user && user.name && user.name.substring(0, 2)}
                              </span>
                            </span>
                            <span className="ml-5">{user.name || "N/A"}</span>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                            {moment(user.created_at)
                              .tz("Etc/GMT-0")
                              .format("llll")}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                            {user.mobile_number}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                            {user.balance || "0.00"}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                            {user.card_number}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
        <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
          <p className="text-sm text-gray-700">
            Showing{" "}
            <span className="font-medium">{(currentPage - 1) * limit + 1}</span>{" "}
            to{" "}
            <span className="font-medium">
              {Math.min(currentPage * limit, totalUsers)}
            </span>{" "}
            of <span className="font-medium">{totalUsers}</span> results
          </p>
          <nav
            aria-label="Pagination"
            className="isolate inline-flex -space-x-px rounded-md shadow-sm"
          >
            <button
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
              className={`relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 ${
                currentPage === 1 ? "cursor-not-allowed opacity-50" : ""
              }`}
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon aria-hidden="true" className="h-5 w-5" />
            </button>

            {renderPageNumbers()}

            <button
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
              className={`relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 ${
                currentPage === totalPages
                  ? "cursor-not-allowed opacity-50"
                  : ""
              }`}
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon aria-hidden="true" className="h-5 w-5" />
            </button>
          </nav>
        </div>
      </div>
    </Card>
  );
};

export default MobileAppUsers;
