import React from "react";
import Card from "components/card";
import { CSVLink } from "react-csv";
import { userStaticsHeader } from "Constant/Constant";
import UserTransactionTenStackTable from "./userTransactionTenStackTable";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";

function RecentTransactions(props: {
  tableData: any;
  setTransactionButtons: any;
  transactionButtons: any;
  setCashAndOnlinePayments: any;
  cashAndOnlinePayments: any;
  onPageChange: (newPage: number) => void;
  page: number;
}) {
  const {
    tableData,
    transactionButtons,
    setTransactionButtons,
    setCashAndOnlinePayments,
    cashAndOnlinePayments,
    onPageChange,
    page,
  } = props;

  // Get all transactions
  // Ensure tableData.data is an array before filtering
  const combinedTransactions = Array.isArray(tableData.data)
    ? tableData.data
    : [];
  const cashTransactions = tableData.data?.cashTransactions || [];
  const onlineTransactions = tableData.data?.onlineTransactions || [];

  // Filter cash transactions
  const cash = cashTransactions.filter(
    (e: any) => e.merchantCheck?.toLowerCase() === "cash"
  );

  // Filter online transactions
  const online = onlineTransactions.filter(
    (e: any) => e.merchantCheck?.toLowerCase() !== "cash"
  );

  // Remove topups (machine_code !== "0000")
  const tableDataRemoveTopups = combinedTransactions.filter(
    (e: any) => e.machine_code !== "0000"
  );

  // Filter Dish Wash Liquid Data
  const dishWashLiquidData = tableDataRemoveTopups.filter(
    (e: any) => e.machine_code === "SPAR"
  );

  // Filter Topups
  const tableDataTopups = combinedTransactions.filter((e: any) =>
    [
      "easypaisa",
      "jazzcash",
      "Debit/ Credit Card",
      "Easypaisa",
      "Jazzcash",
      "Neem",
      "0000",
    ].includes(e.machine_code)
  );

  console.log("Topup", tableDataTopups);
  // Determine displayed data
  const getDisplayData = () => {
    switch (transactionButtons) {
      case "butterfly":
        return cashAndOnlinePayments === "online" ? online : cash;
      case "oil":
        return tableDataRemoveTopups;
      case "dishwash":
        return dishWashLiquidData;
      case "topup":
        return tableDataTopups;
      default:
        return combinedTransactions;
    }
  };

  const displayData = getDisplayData();

  // Pagination data from the backend
  const getTotalPages = () => {
    if (transactionButtons === "butterfly") {
      return cashAndOnlinePayments === "online"
        ? tableData.totalOnlinePages || 1
        : tableData.totalCashPages || 1;
    }
    return tableData.totalPages || 1;
  };

  const totalCount = tableData.totalCount || 0;
  const totalPages = getTotalPages();
  // Handle page change
  const handlePageChange = (newPage: number) => {
    if (newPage >= 1 && newPage <= totalPages) {
      onPageChange(newPage);
    }
  };

  // Render page numbers for pagination
  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxPage = totalPages;

    for (let i = 1; i <= maxPage; i++) {
      if (i <= 3 || i >= maxPage - 2 || (i >= page - 1 && i <= page + 1)) {
        pageNumbers.push(i);
      }
    }

    return pageNumbers.map((pageNum, index) => (
      <button
        key={index}
        onClick={() => handlePageChange(pageNum)}
        className={`relative inline-flex items-center px-4 py-2 text-sm font-medium ${
          page === pageNum
            ? "z-10 bg-teal-600 text-white"
            : "text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20"
        }`}
      >
        {pageNum}
      </button>
    ));
  };

  return (
    <Card extra={"w-full h-full sm:overflow-auto px-6"}>
      <header className="relative flex items-center justify-between pt-4">
        <div className="text-xl font-bold text-navy-700 dark:text-white">
          {`Recent Transactions (${totalCount})`}
        </div>
        <div>
          <CSVLink
            data={displayData}
            headers={userStaticsHeader}
            className="block rounded-md bg-teal-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-teal-500"
          >
            Export as CSV
          </CSVLink>
        </div>
      </header>

      {/* Transaction Buttons */}
      <div>
        <button
          className={`rounded-full ${
            transactionButtons === "butterfly"
              ? "bg-teal-600 text-white"
              : "text-black bg-white"
          } mr-2 px-5 py-2 text-sm font-semibold shadow-sm ring-1 ring-teal-600`}
          onClick={() => setTransactionButtons("butterfly")}
        >
          Butterfly
        </button>
        <button
          className={`rounded-full ${
            transactionButtons === "oil"
              ? "bg-teal-600 text-white"
              : "text-black bg-white"
          } mr-2 px-5 py-2 text-sm font-semibold shadow-sm ring-1 ring-teal-600`}
          onClick={() => setTransactionButtons("oil")}
        >
          Refill Stations
        </button>
        <button
          className={`rounded-full ${
            transactionButtons === "topup"
              ? "bg-teal-600 text-white"
              : "text-black bg-white"
          } mr-2 px-5 py-2 text-sm font-semibold shadow-sm ring-1 ring-teal-600`}
          onClick={() => setTransactionButtons("topup")}
        >
          User Topup
        </button>
      </div>

      {/* Butterfly Payment Options */}
      {transactionButtons === "butterfly" && (
        <div className="mt-2">
          <button
            className={`rounded-full ${
              cashAndOnlinePayments === "online"
                ? "bg-teal-600 text-white"
                : "text-black bg-white"
            } mr-2 px-5 py-2 text-sm font-semibold shadow-sm ring-1 ring-teal-600`}
            onClick={() => setCashAndOnlinePayments("online")}
          >
            Online payments ({online.length})
          </button>
          <button
            className={`rounded-full ${
              cashAndOnlinePayments === "cash"
                ? "bg-teal-600 text-white"
                : "text-black bg-white"
            } mr-2 px-5 py-2 text-sm font-semibold shadow-sm ring-1 ring-teal-600`}
            onClick={() => setCashAndOnlinePayments("cash")}
          >
            Cash payments ({cash.length})
          </button>
        </div>
      )}

      {/* Transactions Table */}
      <UserTransactionTenStackTable tableData={displayData} />

      {/* Pagination */}
      <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
        <p className="text-sm text-gray-700">
          Showing <span className="font-medium">{page}</span> of{" "}
          <span className="font-medium">{totalPages}</span> pages
        </p>
        <nav className="inline-flex -space-x-px">{renderPageNumbers()}</nav>
      </div>
    </Card>
  );
}

export default RecentTransactions;
