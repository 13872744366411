import {
  BASE_URL,
  barChartCustomData,
  decryptObject,
  formatDate,
} from "Constant/Constant";
import React, { useEffect, useState } from "react";
import PieChartCard from "views/admin/Dashboard/components/PieChartCard";
import BarChart from "views/admin/Dashboard/components/BarChart";
import { useParams } from "react-router-dom";

const Sales = () => {
  //localStorage
  const authData = localStorage.getItem("auth");
  // const localStorageData = JSON.parse(authData);
  const localStorageDataDecrypted = JSON.parse(authData);
  const localStorageData = decryptObject(
    localStorageDataDecrypted.encryptedUser
  );
  const { id } = useParams();

  //bar Chart
  const [barChartLoader, setBarChartLoader] = useState(false);
  const [barChartData, setBarChartData] = useState(barChartCustomData);
  const [transactionBody, setTransactionBody] = useState([]);
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [totalTransactions, setTotalTransactions] = useState(0);
  //Pie Chart
  const [pieChartLoader, setPieChartLoader] = useState(false);
  const [pieChartBody, setPieChartBody] = useState([]);
  const [pieChartStats, setPieChartStats] = useState(null);
  const [pieRevenue, setPieRevenue] = useState({});
  const [pieVolume, setPieVolume] = useState({});
  const [pieTransaction, setPieTransaction] = useState({});
  const [pieTotal, setPieTotal] = useState({
    totalRevenue: 0,
    totalTransaction: 0,
    totalVolume: 0,
  });
  const [pieDates, setPieDates] = useState({
    startDate: formatDate(new Date(Date.now() - 864e5)),
    endDate: formatDate(new Date()),
  });

  useEffect(() => {
    setBarChartLoader(true);
    const data = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },

      body: JSON.stringify({
        request: {
          method: "getMachineDetails",
          data: {
            company_code: localStorageData.user.company_code,
            machine_id: id,
          },
        },
      }),
    };
    const result = fetch(`${BASE_URL}machines`, data)
      .then((response: any) => response.json(response))
      .catch((error) => console.log("error", error));
    result.then((data: any) => {
      setBarChartData({
        weekly: {
          revenue:
            data?.response?.data?.machine?.sales_page?.last_week?.Revenue || {},
          transaction:
            data?.response?.data?.machine?.sales_page?.last_week?.Transaction ||
            {},
        },
        monthly: {
          revenue:
            data?.response?.data?.machine?.sales_page?.last_four_months
              ?.Revenue || {},
          transaction:
            data?.response?.data?.machine?.sales_page?.last_four_months
              ?.Transaction || {},
        },
      });

      const lastweekRvenue = Object.entries(
        data?.response?.data?.machine?.sales_page?.last_week?.Revenue || {}
      );
      let lastweekRvenueEntries = {};
      for (const [key, value] of lastweekRvenue) {
        Object.assign(lastweekRvenueEntries, value);
      }

      const monthlyRevenue = Object.entries(
        data?.response?.data?.machine?.sales_page?.last_four_months?.Revenue ||
          {}
      );
      let monthlyRvenueEntries = {};
      for (const [key, value] of monthlyRevenue) {
        Object.assign(monthlyRvenueEntries, value);
      }

      const lastweekTransaction = Object.entries(
        data?.response?.data?.machine?.sales_page?.last_week?.Transaction || {}
      );
      let lastweekTransactionEntries = {};
      for (const [key, value] of lastweekTransaction) {
        Object.assign(lastweekTransactionEntries, value);
      }

      const monthlyTransaction = Object.entries(
        data?.response?.data?.machine?.sales_page?.last_four_months
          ?.Transaction || {}
      );
      let monthlyTransactionEntries = {};
      for (const [key, value] of monthlyTransaction) {
        Object.assign(monthlyTransactionEntries, value);
      }

      const combineRevenue = {};
      Object.assign(
        combineRevenue,
        lastweekRvenueEntries,
        monthlyRvenueEntries
      );

      const combineTransaction = {};
      Object.assign(
        combineTransaction,
        lastweekTransactionEntries,
        monthlyTransactionEntries
      );

      let revenueValues = Object.values(combineRevenue);
      let revenueKeys = Object.keys(combineRevenue);

      let transactionValues = Object.values(combineTransaction);
      let transactionKeys = Object.keys(combineTransaction);

      let revenueValuesToArrayOfObject =
        revenueValues &&
        revenueValues.map(function (el, i) {
          return {
            revenueAmount: el,
            revenueValues: revenueKeys[i],
            transactionAmount: transactionKeys[i],
            transactionValues: transactionValues[i],
          };
        });

      setTransactionBody(revenueValuesToArrayOfObject);

      //   let revenue1 = data?.response?.data?.last_week?.Revenue.map(
      //     (e: any, i: any) => Object.values(e)[0]
      //   ).reduce((a: any, b: any) => a + b, 0);
      //   let revenue2 = data?.response?.data?.user_transaction?.Revenue.map(
      //     (e: any, i: any) => Object.values(e)[0]
      //   ).reduce((a: any, b: any) => a + b, 0);

      //   let transaction1 = data?.response?.data?.last_week?.Transaction.map(
      //     (e: any, i: any) => Object.values(e)[0]
      //   ).reduce((a: any, b: any) => a + b, 0);
      //   let transaction2 =
      //     data?.response?.data?.user_transaction?.Transaction.map(
      //       (e: any, i: any) => Object.values(e)[0]
      //     ).reduce((a: any, b: any) => a + b, 0);

      setTotalRevenue(data?.response?.data?.machine?.sales_page?.total_revenue);
      setTotalTransactions(
        data?.response?.data?.machine?.sales_page?.total_transactions
      );
      setBarChartLoader(false);
    });
  }, []);

  //PieDates
  //error in this api
  useEffect(() => {
    setPieChartLoader(true);
    const data = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },

      body: JSON.stringify({
        request: {
          method: "getMachineSalesPageByDate",
          data: {
            company_code: localStorageData.user.company_code,
            start_date: pieDates.startDate,
            end_date: pieDates.endDate,
            machine_id: id,
          },
        },
      }),
    };
    const result = fetch(`${BASE_URL}machines`, data)
      .then((response: any) => response.json(response))
      .catch((error) => {
        console.log("error", error);
      });
    result.then((data) => {
      setPieChartStats(data?.response?.data);
      setPieRevenue(data?.response?.data?.revenue);
      setPieVolume(data?.response?.data?.volume);
      setPieTransaction(data?.response?.data?.transactions);
      setPieTotal({
        totalRevenue: data?.response?.data?.total_revenue,
        totalTransaction: data?.response?.data?.total_transactions,
        totalVolume: data?.response?.data?.total_volume,
      });
      setPieChartLoader(false);

      const volumeKeysData = Object.keys(data?.response?.data?.volume);
      const volumeValuesData = Object.values(data?.response?.data?.volume);

      const transactionKeysData = Object.keys(
        data?.response?.data?.transactions
      );

      const transactionValuesData = Object.values(
        data?.response?.data?.transactions
      );

      const revenueKeysData = Object.keys(data?.response?.data?.revenue);
      const revenueValuesData = Object.values(data?.response?.data?.revenue);

      if (volumeKeysData.length > 1) {
        let combineAll =
          volumeKeysData &&
          volumeKeysData.map((vol, i) => {
            return {
              volumeName: vol,
              volumePrice: volumeValuesData[i],
              transactionName: transactionKeysData[i],
              transactionPrice: transactionValuesData[i],
              revenueName: revenueKeysData[i],
              revenuePrice: revenueValuesData[i],
            };
          });

        Object.assign(combineAll[combineAll.length - 1], {
          totalRevenue: data?.response?.data?.total_revenue,
          totalTransactions: data?.response?.data?.total_transactions,
          totalVolume: data?.response?.data?.total_volume,
        });
        setPieChartBody(combineAll);
      }
    });
  }, [pieDates.startDate, pieDates.endDate]);

  const handleValueChange = (newValue: any) => {
    setPieDates(newValue);
  };

  return (
    <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-2">
      <BarChart
        barChartDataProps={barChartData}
        totalRevenue={totalRevenue}
        totalTransaction={totalTransactions}
        barChartLoader={barChartLoader}
      />

      <PieChartCard
        value={pieDates}
        handleValueChange={handleValueChange}
        pieRevenue={pieRevenue}
        pieVolume={pieVolume}
        pieTransaction={pieTransaction}
        pieChartLoader={pieChartLoader}
        pieTotal={pieTotal}
      />
    </div>
  );
};

export default Sales;
