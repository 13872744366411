import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ProductDetailsLoader from "components/Loader/productDetailsLoader";
import LocationsTable from "./components/LocationsTable";

import {
  locationsByCompanySelector,
  getLocationByCompany,
} from "../../../Redux/Features/Dashboard/GetLocationsByCompany";
import { BASE_URL_TWO, locationsTableData } from "Constant/Constant";
import TableLoader from "components/TableLoader/TableLoader";
import MapLocation from "./components/MapLocation";
import axios from "axios";

const Tables = () => {
  const dispatch = useDispatch<any>();
  const [machineLoader, setMachineLoader] = useState(false);
  const [machineData, setMachineData] = useState([]);

  const {
    locationsByCompanyData,
    locationsByCompanyLoader,
    locationsByCompanyErrors,
  } = useSelector(locationsByCompanySelector);

  useEffect(() => {
    dispatch(getLocationByCompany());
  }, [dispatch]);


  useEffect(() => {
    const fetchMachines = async () => {
      setMachineLoader(true);
      try {
        const res = await axios.get(`${BASE_URL_TWO}allMachines`);
        const machines = res.data?.data || {}; // Default to an empty object if undefined
        
        // Extract all machine data dynamically and flatten nested arrays
        const machineList = Object.values(machines).flat();
  
        console.log(machineList);
  
        setMachineData(machineList);
      } catch (error) {
        console.error("Error fetching machines:", error);
      } finally {
        setMachineLoader(false);
      }
    };
  
    fetchMachines();
  }, []);
  
  return (
    <div className=" bg-white m-3 rounded-md dark:!bg-navy-900">
      <div className="w-full p-2 rounded-sm">
        {
          !machineLoader ?
            <MapLocation machineData={machineData} /> :
            <h1>Loading map...</h1>
        }

      </div>
      {!locationsByCompanyLoader ? (
        <LocationsTable tableData={machineData} />
      ) : (
        <TableLoader tableLoaderData={locationsTableData} />
      )}
    </div>
  );
};

export default Tables;
