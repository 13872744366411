import { COMPANY, allMachines, decryptObject } from "Constant/Constant";
import { timeConverter } from "Constant/TimestampConstant";
// import { pushNotification } from "Constant/NotificationConstant";
import { useState } from "react";
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";
import AddMachine from "./AddMachine";

function MachineTable(props: {
  tableData: any;
  machineStatusData: any;
  currentMachine: any;
  setCurrentMachine: any;
}) {
  const { tableData, machineStatusData, currentMachine, setCurrentMachine } =
    props;

  //localStorage
  const authData = localStorage.getItem("auth");
  //  const localStorageData = JSON.parse(authData);
  const localStorageDataDecrypted = JSON.parse(authData);
  const localStorageData = decryptObject(
    localStorageDataDecrypted.encryptedUser
  );

  const [machineNumber, setMachineNumber] = useState("");
  const [activeButton, setActiveButton] = useState("All");
  const [open, setOpen] = useState(false);

  const mergeData = tableData.map((e: any) => {
    const addData =
      machineStatusData &&
      machineStatusData.find((f: any) => e._id === f.machine_code);
    return { ...e, ...addData };
  });

  let filterDataForMachinesAll = mergeData && mergeData;
  let filterDataForMachinesActive =
    mergeData && mergeData.filter((e: any) => e.status === "g");
  let filterDataForMachinesInactive =
    mergeData && mergeData.filter((e: any) => e.status === "r");
  let filterDataForMachinesPending =
    mergeData && mergeData.filter((e: any) => e.status === "a");
  let filterDataForMachinesNotDefined =
    mergeData && mergeData.filter((e: any) => e.status === undefined);

  let showData =
    activeButton === "Active"
      ? filterDataForMachinesActive
      : activeButton === "Inactive"
      ? filterDataForMachinesInactive
      : activeButton === "Pending"
      ? filterDataForMachinesPending
      : activeButton === "NotDefined"
      ? filterDataForMachinesNotDefined
      : filterDataForMachinesAll;

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900 dark:!text-gray-500">
            Deployed Machines
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            A list of all the machines in your account including their machine
            id, locations, type and brands.
          </p>
        </div>

        <div>
          {localStorageData.user.user_role === COMPANY ? null : (
            <button
              onClick={() => setOpen(true)}
              className="shadow-xs rounded-md bg-teal-600 px-3.5 py-2.5 text-sm font-semibold text-white hover:bg-teal-500 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
            >
              Add Machine
            </button>
          )}
        </div>
      </div>

      <div className="mt-3">
        <input
          type="text"
          name="text"
          id="text"
          className="block w-full rounded-md border-0 p-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-600 sm:text-sm sm:leading-6"
          placeholder="Search by machine number"
          onChange={(e: any) => setMachineNumber(e.target.value)}
        />
      </div>

      <div className="mt-2 flex gap-2">
        {localStorageData.user.user_role === COMPANY ? null : (
          <div>
            <button
              onClick={() => setCurrentMachine("Butterfly")}
              className={`rounded-full ${
                currentMachine === "Butterfly" ? "bg-teal-600" : "bg-white "
              }  mr-2 px-5 py-2 text-sm font-semibold ${
                currentMachine === "Butterfly"
                  ? "text-white"
                  : "text-black dark:text-gray-900"
              } shadow-sm ring-1 ring-inset ring-teal-600 `}
            >
              {" "}
              Butterfly
            </button>
            <button
              onClick={() => setCurrentMachine("BodyWash")}
              className={`rounded-full ${
                currentMachine === "BodyWash" ? "bg-teal-600" : "bg-white "
              }  mr-2 px-5 py-2 text-sm font-semibold ${
                currentMachine === "BodyWash"
                  ? "text-white"
                  : "text-black dark:text-gray-900"
              } shadow-sm ring-1 ring-inset ring-teal-600 `}
            >
              Body Wash
            </button>
            <button
              onClick={() => setCurrentMachine("Cooking Oil")}
              className={`rounded-full ${
                currentMachine === "Cooking Oil" ? "bg-teal-600" : "bg-white "
              }  mr-2 px-5 py-2 text-sm font-semibold ${
                currentMachine === "Cooking Oil"
                  ? "text-white"
                  : "text-black dark:text-gray-900"
              } shadow-sm ring-1 ring-inset ring-teal-600 `}
            >
              Cooking oil
            </button>
            <button
              onClick={() => setCurrentMachine("Dishwash")}
              className={`rounded-full ${
                currentMachine === "Dishwash" ? "bg-teal-600" : "bg-white "
              }  mr-2 px-5 py-2 text-sm font-semibold ${
                currentMachine === "Dishwash"
                  ? "text-white"
                  : "text-black dark:text-gray-900"
              } shadow-sm ring-1 ring-inset ring-teal-600 `}
            >
              Dishwash
            </button>
            <button
              onClick={() => setCurrentMachine("Handwash")}
              className={`rounded-full ${
                currentMachine === "Handwash" ? "bg-teal-600" : "bg-white "
              }  mr-2 px-5 py-2 text-sm font-semibold ${
                currentMachine === "Handwash"
                  ? "text-white"
                  : "text-black dark:text-gray-900"
              } shadow-sm ring-1 ring-inset ring-teal-600 `}
            >
              Handwash
            </button>
            <button
              onClick={() => setCurrentMachine("Laundry")}
              className={`rounded-full ${
                currentMachine === "Laundry" ? "bg-teal-600" : "bg-white "
              }  mr-2 px-5 py-2 text-sm font-semibold ${
                currentMachine === "Laundry"
                  ? "text-white"
                  : "text-black dark:text-gray-900"
              } shadow-sm ring-1 ring-inset ring-teal-600 `}
            >
              Laundry
            </button>
            <button
              onClick={() => setCurrentMachine("Shampoo")}
              className={`rounded-full ${
                currentMachine === "Shampoo" ? "bg-teal-600" : "bg-white "
              }  mr-2 px-5 py-2 text-sm font-semibold ${
                currentMachine === "Shampoo"
                  ? "text-white"
                  : "text-black dark:text-gray-900"
              } shadow-sm ring-1 ring-inset ring-teal-600 `}
            >
              Shampo
            </button>
            <button
              onClick={() => setCurrentMachine("Surface Cleaner")}
              className={`rounded-full ${
                currentMachine === "Surface Cleaner"
                  ? "bg-teal-600"
                  : "bg-white "
              }  mr-2 px-5 py-2 text-sm font-semibold ${
                currentMachine === "Surface Cleaner"
                  ? "text-white"
                  : "text-black dark:text-gray-900"
              } shadow-sm ring-1 ring-inset ring-teal-600 `}
            >
              Surface Cleaner
            </button>
            <button
              onClick={() => setCurrentMachine("Unknown")}
              className={`rounded-full ${
                currentMachine === "Unknown" ? "bg-teal-600" : "bg-white "
              }  mr-2 px-5 py-2 text-sm font-semibold ${
                currentMachine === "Unknown"
                  ? "text-white"
                  : "text-black dark:text-gray-900"
              } shadow-sm ring-1 ring-inset ring-teal-600 `}
            >
              Remaining
            </button>
          </div>
        )}
      </div>
      <div className="mt-3 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="ring-black overflow-hidden shadow ring-1 ring-opacity-5 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50 dark:!bg-gray-900 ">
                  <tr>
                    <th
                      scope="col"
                      className="  py-3.5 pl-4 pr-3 text-center text-sm font-semibold text-gray-900 dark:!text-white sm:pl-6"
                    >
                      SNO
                    </th>
                    <th></th>
                    <th
                      scope="col"
                      className="  py-3.5 pl-4 pr-3 text-center text-sm font-semibold text-gray-900 dark:!text-white sm:pl-6"
                    >
                      Machine Id
                    </th>
                    <th
                      scope="col"
                      className="  py-3.5 pl-4 pr-3 text-center text-sm font-semibold text-gray-900 dark:!text-white sm:pl-6"
                    >
                      Locations
                    </th>
                    <th
                      scope="col"
                      className="  py-3.5 pl-4 pr-3 text-center text-sm font-semibold text-gray-900 dark:!text-white sm:pl-6"
                    >
                      Type
                    </th>
                    <th
                      scope="col"
                      className="  py-3.5 pl-4 pr-3 text-center text-sm font-semibold text-gray-900 dark:!text-white sm:pl-6"
                    >
                      Status
                    </th>
                    <th
                      scope="col"
                      className="  py-3.5 pl-4 pr-3 text-center text-sm font-semibold text-gray-900 dark:!text-white sm:pl-6"
                    >
                      Last active
                    </th>
                    <th
                      scope="col"
                      className="  py-3.5 pl-4 pr-3 text-center text-sm font-semibold text-gray-900 dark:!text-white sm:pl-6"
                    >
                      Action
                    </th>
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-0"
                    >
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {machineNumber
                    ? showData.map((machine: any, key: any) => (
                        <tr key={machine.machine_code}>
                          <td className="whitespace-nowrap text-center text-sm font-medium text-gray-900 dark:!text-gray-500 sm:pl-0">
                            {key + 1}
                          </td>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-center text-sm font-medium text-gray-900 dark:!text-gray-500 sm:pl-0">
                            {machine.machine_code}
                          </td>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-center text-sm font-medium text-gray-900 dark:!text-gray-500 sm:pl-0">
                            {machine.machine_name}
                          </td>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-center text-sm font-medium text-gray-900 dark:!text-gray-500 sm:pl-0">
                            {machine.machine_type}
                          </td>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-center text-sm font-medium text-gray-900 dark:!text-gray-500 sm:pl-0"></td>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-center text-sm font-medium text-gray-900 dark:!text-gray-500 sm:pl-0">
                            {machineStatusData &&
                            machineStatusData.find(
                              (e: any) =>
                                e.machine_code === machine.machine_code
                            )
                              ? timeConverter(
                                  machineStatusData.find(
                                    (e: any) =>
                                      e.machine_code === machine.machine_code
                                  ).last_updated
                                )
                              : null}
                          </td>
                          <td className="flex justify-center whitespace-nowrap py-4 pl-4 pr-3 text-center text-sm font-medium text-gray-900 dark:!text-gray-500 sm:pl-0">
                            <Link
                              to={`/admin/machine-details/${machine.machine_code}/${machine.machine_type}`}
                              className="text-center"
                            >
                              <button
                                type="button"
                                className="block rounded-md bg-teal-600 px-5 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                              >
                                Visit{" "}
                              </button>
                            </Link>
                          </td>
                        </tr>
                      ))
                    : tableData.map((machine: any, key: number) => (
                        <tr key={machine.machine_code}>
                          <td className="whitespace-nowrap text-center text-sm font-medium text-gray-900 dark:!text-gray-500 sm:pl-0">
                            {key + 1}
                          </td>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-center text-sm font-medium text-gray-900 dark:!text-gray-500 sm:pl-0">
                            {machine.machine_code}
                          </td>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-center text-sm font-medium text-gray-900 dark:!text-gray-500 sm:pl-0">
                            {machine.machine_name}
                          </td>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-center text-sm font-medium text-gray-900 dark:!text-gray-500 sm:pl-0">
                            {machine.machine_type}
                          </td>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-center text-sm font-medium text-gray-900 dark:!text-gray-500 sm:pl-0">
                            {machineStatusData &&
                            machineStatusData.find(
                              (e: any) =>
                                e.machine_code === machine.machine_code
                            ) ? (
                              machineStatusData.find(
                                (e: any) =>
                                  e.machine_code === machine.machine_code
                              ).status === "r" ? (
                                <span className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/20">
                                  Inactive
                                </span>
                              ) : machineStatusData.find(
                                  (e: any) =>
                                    e.machine_code === machine.machine_code
                                ).status === "g" ? (
                                <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                                  Active
                                </span>
                              ) : machineStatusData.find(
                                  (e: any) =>
                                    e.machine_code === machine.machine_code
                                ).status === "a" ? (
                                <span className="inline-flex items-center rounded-md bg-amber-50 px-2 py-1 text-xs font-medium text-amber-700 ring-1 ring-inset ring-amber-600/20">
                                  Pending
                                </span>
                              ) : (
                                <span className="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-600/20">
                                  Not defined
                                </span>
                              )
                            ) : (
                              <span className="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-600/20">
                                Not defined
                              </span>
                            )}
                          </td>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-center text-sm font-medium text-gray-900 dark:!text-gray-500 sm:pl-0">
                            {machineStatusData &&
                            machineStatusData.find(
                              (e: any) =>
                                e.machine_code === machine.machine_code
                            )
                              ? timeConverter(
                                  machineStatusData.find(
                                    (e: any) =>
                                      e.machine_code === machine.machine_code
                                  ).last_updated
                                )
                              : null}
                          </td>
                          <td className="flex justify-center whitespace-nowrap py-4 pl-4 pr-3 text-center text-sm font-medium text-gray-900 dark:!text-gray-500 sm:pl-0">
                            <Link
                              to={`/admin/machine-details/${machine.machine_code}/${machine.machine_type}`}
                              className="text-center"
                            >
                              <button
                                type="button"
                                className="block rounded-md bg-teal-600 px-5 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                              >
                                Visit{" "}
                              </button>
                            </Link>
                          </td>
                        </tr>
                      ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <AddMachine open={open} setOpen={setOpen} />
    </div>
  );
}

export default MachineTable;
