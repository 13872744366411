/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Widget from "components/widget/Widget";
import DashboardCardLoader from "components/Loader/DashboardCardLoader";
import ProductDetailsLoader from "components/Loader/productDetailsLoader";
import BarChart from "views/admin/Dashboard/components/BarChart";
import PieChartCard from "views/admin/Dashboard/components/PieChartCard";
import UserStaticsTable from "views/admin/Dashboard/components/UserStaticsTable";
import MachineStatics from "views/admin/Dashboard/components/MachineStaticsTabel";
import {
  BASE_URL,
  BASE_URL_TWO,
  LOCAL_BASE_URL,
  COMPANY,
  barChartCustomData,
  decryptObject,
  formatDate,
} from "Constant/Constant";
import {
  dashboardSelector,
  getPlasticSavedByCompany,
} from "../../../Redux/Features/Dashboard/getPlasticSavedByCompany";
import {
  machinesByCompanySelector,
  getMachinesByCompany,
} from "../../../Redux/Features/Dashboard/GetMachinesByCompany";
import {
  locationsByCompanySelector,
  getLocationByCompany,
} from "../../../Redux/Features/Dashboard/GetLocationsByCompany";
import {
  bottleDispensedByCompanySelector,
  getBottleDispensedByCompany,
} from "../../../Redux/Features/Dashboard/GetBottleDispensedByCompany";

import { IoDocuments } from "react-icons/io5";
import { MdBarChart, MdDashboard } from "react-icons/md";
import { darkModeSelector } from "Redux/Features/DarkMode/DarkMode";
import axios from "axios";
import RecentTransactions from "./components/RecentTransactions";
import ButterflyPieChart from "./components/ButterflyPieChart";
import ButterflyLineChart from "./components/ButterflyLineChart";
import OilChart from "./components/OilAndCleanerCharts/OilChart";
import CleaningProductChart from "./components/OilAndCleanerCharts/CleaningProductChart";
import MobileAppUsers from "./components/MobileAppUsers";

const Dashboard = () => {
  //localStorage
  const authData = localStorage.getItem("auth");
  const localStorageDataDecrypted = JSON.parse(authData);
  const localStorageData = decryptObject(
    localStorageDataDecrypted.encryptedUser
  );

  const dispatch = useDispatch<any>();

  //plasticSavedByCompany
  const { data, loading } = useSelector(dashboardSelector);

  //active machines
  const { machinesByCompanyData, machinesByCompanyLoader } = useSelector(
    machinesByCompanySelector
  );

  //active locations
  const {
    locationsByCompanyData,
    locationsByCompanyLoader,
    locationsByCompanyErrors,
  } = useSelector(locationsByCompanySelector);

  //bottle dispensed
  // const {
  //   bottleDispensedByCompanyData,
  //   BottleDispensedByCompanyLoader,
  //   bottleDispensedByCompanyErrors,
  // } = useSelector(bottleDispensedByCompanySelector);

  //calculations
  const [calculationsLoading, setCalculationsLoading] = useState(true);
  const [calculationsData, setCalculationsData] = useState({
    activeMachines: 0,
    activeLocations: 0,
    bottleDispensed: 0,
    plasticSaved: 0,
    napkins: 0,
  });
  //user Statics

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [userStaticsLoader, setUserStaticsLoader] = useState(true);
  const [userStatsData, setUserStatsData] = useState([]);
  //recent transactions
  const [recentTransactionsLoader, setRecentTransactionLoader] = useState(true);
  const [recentTransactionsData, setRecentTransactionsData] = useState([]);
  const [transactionButtons, setTransactionButtons] = useState("butterfly");
  const [cashAndOnlinePayments, setCashAndOnlinePayments] = useState("online");
  //bar Chart
  const [barChartLoader, setBarChartLoader] = useState(false);
  const [barChartData, setBarChartData] = useState(barChartCustomData);
  const [transactionBody, setTransactionBody] = useState([]);
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [totalTransactions, setTotalTransactions] = useState(0);
  //Pie Chart
  const [pieChartLoader, setPieChartLoader] = useState(false);
  const [pieChartBody, setPieChartBody] = useState([]);
  const [pieChartStats, setPieChartStats] = useState(null);
  const [pieRevenue, setPieRevenue] = useState({});
  const [pieVolume, setPieVolume] = useState({});
  const [pieTransaction, setPieTransaction] = useState({});
  const [pieTotal, setPieTotal] = useState({
    totalRevenue: 0,
    totalTransaction: 0,
    totalVolume: 0,
  });
  const [pieDates, setPieDates] = useState({
    startDate: formatDate(new Date(Date.now() - 864e5)),
    endDate: formatDate(new Date()),
  });

  useEffect(() => {
    dispatch(getLocationByCompany());
    dispatch(getBottleDispensedByCompany());
  }, [dispatch]);

  useEffect(() => {
    barChartDataApi();
  }, []);

  const barChartDataApi = async () => {
    if (localStorageData.user.user_role === COMPANY) {
      //corporate
      const machineCodeDataa = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },

        body: JSON.stringify({
          machine: localStorageData?.user?.machines,
        }),
      };
      const barChartCompany = fetch(
        `${BASE_URL_TWO}cooperateBarChart`,
        machineCodeDataa
      )
        .then((response: any) => response.json(response))
        .catch((error) => console.log("error", error));

      barChartCompany.then((data: any) => {
        setBarChartData({
          weekly: {
            revenue: data?.response?.data?.last_week?.Revenue,
            transaction: data?.response?.data?.last_week?.Transaction,
          },
          monthly: {
            revenue: data?.response?.data?.user_transaction?.Revenue,
            transaction: data?.response?.data?.user_transaction?.Transaction,
          },
        });

        const lastweekRvenue = Object.entries(
          data?.response?.data?.last_week?.Revenue
        );
        let lastweekRvenueEntries = {};
        for (const [key, value] of lastweekRvenue) {
          Object.assign(lastweekRvenueEntries, value);
        }

        const monthlyRevenue = Object.entries(
          data?.response?.data?.user_transaction?.Revenue
        );
        let monthlyRvenueEntries = {};
        for (const [key, value] of monthlyRevenue) {
          Object.assign(monthlyRvenueEntries, value);
        }

        const lastweekTransaction = Object.entries(
          data?.response?.data?.last_week?.Transaction
        );
        let lastweekTransactionEntries = {};
        for (const [key, value] of lastweekTransaction) {
          Object.assign(lastweekTransactionEntries, value);
        }

        const monthlyTransaction = Object.entries(
          data?.response?.data?.user_transaction?.Transaction
        );
        let monthlyTransactionEntries = {};
        for (const [key, value] of monthlyTransaction) {
          Object.assign(monthlyTransactionEntries, value);
        }

        const combineRevenue = {};
        Object.assign(
          combineRevenue,
          lastweekRvenueEntries,
          monthlyRvenueEntries
        );

        const combineTransaction = {};
        Object.assign(
          combineTransaction,
          lastweekTransactionEntries,
          monthlyTransactionEntries
        );

        let revenueValues = Object.values(combineRevenue);
        let revenueKeys = Object.keys(combineRevenue);

        let transactionValues = Object.values(combineTransaction);
        let transactionKeys = Object.keys(combineTransaction);

        let revenueValuesToArrayOfObject =
          revenueValues &&
          revenueValues.map(function (el, i) {
            return {
              revenueAmount: el,
              revenueValues: revenueKeys[i],
              transactionAmount: transactionKeys[i],
              transactionValues: transactionValues[i],
            };
          });

        setTransactionBody(revenueValuesToArrayOfObject);

        let revenue1 = data?.response?.data?.last_week?.Revenue.map(
          (e: any, i: any) => Object.values(e)[0]
        ).reduce((a: any, b: any) => a + b, 0);
        let revenue2 = data?.response?.data?.user_transaction?.Revenue.map(
          (e: any, i: any) => Object.values(e)[0]
        ).reduce((a: any, b: any) => a + b, 0);

        let transaction1 = data?.response?.data?.last_week?.Transaction.map(
          (e: any, i: any) => Object.values(e)[0]
        ).reduce((a: any, b: any) => a + b, 0);
        let transaction2 =
          data?.response?.data?.user_transaction?.Transaction.map(
            (e: any, i: any) => Object.values(e)[0]
          ).reduce((a: any, b: any) => a + b, 0);

        setTotalRevenue(parseInt(revenue1) + parseInt(revenue2));
        setTotalTransactions(parseInt(transaction1) + parseInt(transaction2));
        setBarChartLoader(false);
      });
      // return
    } else {
      //all company chart
      setBarChartLoader(true);

      const data = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },

        body: JSON.stringify({
          request: {
            method: "getUserTransactionsByYearlySumaryByCompany",
            data: {
              company_code: localStorageData.user.company_code,
            },
          },
        }),
      };
      const result = fetch(
        `https://davaam-backend-nodejs-4199d6d4d449.herokuapp.com/fetchDataController`,
        data
      )
        .then((response: any) => response.json(response))
        .catch((error) => console.log("error", error));
      result.then((data: any) => {
        setBarChartData({
          weekly: {
            revenue: data?.data?.last_week?.Revenue,
            transaction: data?.data?.last_week?.Transaction,
          },
          monthly: {
            revenue: data?.data?.user_transaction?.Revenue,
            transaction: data?.data?.user_transaction?.Transaction,
          },
        });

        const lastweekRvenue = Object.entries(data?.data?.last_week?.Revenue);
        let lastweekRvenueEntries = {};
        for (const [key, value] of lastweekRvenue) {
          Object.assign(lastweekRvenueEntries, value);
        }

        const monthlyRevenue = Object.entries(
          data?.data?.user_transaction?.Revenue
        );
        let monthlyRvenueEntries = {};
        for (const [key, value] of monthlyRevenue) {
          Object.assign(monthlyRvenueEntries, value);
        }

        const lastweekTransaction = Object.entries(
          data?.data?.last_week?.Transaction
        );
        let lastweekTransactionEntries = {};
        for (const [key, value] of lastweekTransaction) {
          Object.assign(lastweekTransactionEntries, value);
        }

        const monthlyTransaction = Object.entries(
          data?.data?.user_transaction?.Transaction
        );
        let monthlyTransactionEntries = {};
        for (const [key, value] of monthlyTransaction) {
          Object.assign(monthlyTransactionEntries, value);
        }

        const combineRevenue = {};
        Object.assign(
          combineRevenue,
          lastweekRvenueEntries,
          monthlyRvenueEntries
        );

        const combineTransaction = {};
        Object.assign(
          combineTransaction,
          lastweekTransactionEntries,
          monthlyTransactionEntries
        );

        let revenueValues = Object.values(combineRevenue);
        let revenueKeys = Object.keys(combineRevenue);

        let transactionValues = Object.values(combineTransaction);
        let transactionKeys = Object.keys(combineTransaction);

        let revenueValuesToArrayOfObject =
          revenueValues &&
          revenueValues.map(function (el, i) {
            return {
              revenueAmount: el,
              revenueValues: revenueKeys[i],
              transactionAmount: transactionKeys[i],
              transactionValues: transactionValues[i],
            };
          });

        setTransactionBody(revenueValuesToArrayOfObject);

        let revenue1 = data?.data?.last_week?.Revenue.map(
          (e: any, i: any) => Object.values(e)[0]
        ).reduce((a: any, b: any) => a + b, 0);
        let revenue2 = data?.data?.user_transaction?.Revenue.map(
          (e: any, i: any) => Object.values(e)[0]
        ).reduce((a: any, b: any) => a + b, 0);

        let transaction1 = data?.data?.last_week?.Transaction.map(
          (e: any, i: any) => Object.values(e)[0]
        ).reduce((a: any, b: any) => a + b, 0);
        let transaction2 = data?.data?.user_transaction?.Transaction.map(
          (e: any, i: any) => Object.values(e)[0]
        ).reduce((a: any, b: any) => a + b, 0);

        setTotalRevenue(parseInt(revenue1) + parseInt(revenue2));
        setTotalTransactions(parseInt(transaction1) + parseInt(transaction2));
        setBarChartLoader(false);
      });
    }
  };

  //PieDates
  useEffect(() => {
    pieCharDataApi();
  }, [pieDates.startDate, pieDates.endDate]);

  const pieCharDataApi = () => {
    if (localStorageData.user.user_role === COMPANY) {
      setPieChartLoader(true);
      const data = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },

        body: JSON.stringify({
          machine: localStorageData?.user?.machines,
          startDate: pieDates.startDate,
          endDate: pieDates.endDate,
        }),
      };
      const result = fetch(`${BASE_URL_TWO}cooperatePieChart`, data)
        .then((response: any) => response.json(response))
        .catch((error) => {
          console.log("error", error);
        });

      result.then((data: any) => {
        setPieChartStats(data?.data);
        setPieRevenue(data?.data?.revenue);
        setPieVolume(data?.data?.volume);
        setPieTransaction(data?.data?.transactions);
        setPieTotal({
          totalRevenue: data?.data?.total_revenue,
          totalTransaction: data?.data?.total_transactions,
          totalVolume: data?.data?.total_volume,
        });
        setPieChartLoader(false);

        const volumeKeysData = Object.keys(data?.data?.volume);
        const volumeValuesData = Object.values(data?.data?.volume);

        const transactionKeysData = Object.keys(data?.data?.transactions);

        const transactionValuesData = Object.values(data?.data?.transactions);

        const revenueKeysData = Object.keys(data?.data?.revenue);
        const revenueValuesData = Object.values(data?.data?.revenue);

        if (volumeKeysData.length > 1) {
          let combineAll =
            volumeKeysData &&
            volumeKeysData.map((vol, i) => {
              return {
                volumeName: vol,
                volumePrice: volumeValuesData[i],
                transactionName: transactionKeysData[i],
                transactionPrice: transactionValuesData[i],
                revenueName: revenueKeysData[i],
                revenuePrice: revenueValuesData[i],
              };
            });

          Object.assign(combineAll[combineAll.length - 1], {
            totalRevenue: data?.data?.total_revenue,
            totalTransactions: data?.data?.total_transactions,
            totalVolume: data?.data?.total_volume,
          });
          setPieChartBody(combineAll);
        }
      });
    } else {
      setPieChartLoader(true);
      const data = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },

        body: JSON.stringify({
          request: {
            method: "getUserTransactionsByCompanyAndDate",
            data: {
              company_code: localStorageData.user.company_code,
              start_date: pieDates.startDate,
              end_date: pieDates.endDate,
            },
          },
        }),
      };
      const result = fetch(`${BASE_URL}user/transaction`, data)
        .then((response: any) => response.json(response))
        .catch((error) => {
          console.log("error", error);
        });
      result.then((data) => {
        setPieChartStats(data?.response?.data);
        setPieRevenue(data?.response?.data?.revenue);
        setPieVolume(data?.response?.data?.volume);
        setPieTransaction(data?.response?.data?.transactions);
        setPieTotal({
          totalRevenue: data?.response?.data?.total_revenue,
          totalTransaction: data?.response?.data?.total_transactions,
          totalVolume: data?.response?.data?.total_volume,
        });
        setPieChartLoader(false);

        const volumeKeysData = Object.keys(data?.response?.data?.volume);
        const volumeValuesData = Object.values(data?.response?.data?.volume);

        const transactionKeysData = Object.keys(
          data?.response?.data?.transactions
        );

        const transactionValuesData = Object.values(
          data?.response?.data?.transactions
        );

        const revenueKeysData = Object.keys(data?.response?.data?.revenue);
        const revenueValuesData = Object.values(data?.response?.data?.revenue);

        if (volumeKeysData.length > 1) {
          let combineAll =
            volumeKeysData &&
            volumeKeysData.map((vol, i) => {
              return {
                volumeName: vol,
                volumePrice: volumeValuesData[i],
                transactionName: transactionKeysData[i],
                transactionPrice: transactionValuesData[i],
                revenueName: revenueKeysData[i],
                revenuePrice: revenueValuesData[i],
              };
            });

          Object.assign(combineAll[combineAll.length - 1], {
            totalRevenue: data?.response?.data?.total_revenue,
            totalTransactions: data?.response?.data?.total_transactions,
            totalVolume: data?.response?.data?.total_volume,
          });
          setPieChartBody(combineAll);
        }
      });
    }
  };

  const handleValueChange = (newValue: any) => {
    setPieDates(newValue);
  };

  // let bottlesDispensed = 0;
  // bottleDispensedByCompanyData &&
  //   bottleDispensedByCompanyData.forEach((bottle: any) => {
  //     bottlesDispensed += bottle.total_bottles;
  //   });

  useEffect(() => {
    getMobileAppUsers();
  }, []);

  const getMobileAppUsers = () => {
    if (localStorageData.user.user_role === COMPANY) {
      return;
    } else {
      const apiUrl = `${BASE_URL_TWO}webGetMobileUsers`;

      axios
        .get(apiUrl, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setUserStatsData(response.data);
          setUserStaticsLoader(false);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setUserStaticsLoader(false);
        });
    }
  };

  useEffect(() => {
    fetchAllMobileUsers(currentPage);
  }, [transactionButtons, currentPage]);

  const handleChangePage = (newPage: number) => {
    if (newPage < 1) return; // Prevent negative pages
    setCurrentPage(newPage);
    fetchAllMobileUsers(newPage); // ✅ Fetch new page data
  };

  const fetchAllMobileUsers = async (page: number = 1) => {
    if (localStorageData.user.user_role === COMPANY) {
      return;
    }
    setRecentTransactionLoader(true);

    const apiUrl = `${BASE_URL_TWO}webGetAllTransactions/${transactionButtons}?page=${page}`;
    console.log(apiUrl);
    try {
      const response = await axios.get(apiUrl, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      console.log("Fetched data:", response.data);
      setRecentTransactionsData(response.data);
      setRecentTransactionLoader(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setRecentTransactionLoader(false);
      // toast.error("Failed to fetch data. Please try again later.");
    }
  };

  useEffect(() => {
    fetchAllMobileUsers(currentPage);
  }, [transactionButtons]);

  useEffect(() => {
    setCalculationsLoading(true);
    const apiUrl = `${BASE_URL_TWO}calculations`;
    axios
      .get(apiUrl, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setCalculationsData(response.data.data);
        setCalculationsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setCalculationsLoading(false);
      });
  }, []);

  return (
    <div>
      <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-6">
        {!calculationsLoading ? (
          <Widget
            icon={<MdBarChart className="h-7 w-7" />}
            title={"Total Active Machines"}
            subtitle={
              localStorageData.user.user_role === COMPANY
                ? localStorageData.user.machines.length
                : calculationsData.activeMachines
            }
          />
        ) : (
          <DashboardCardLoader />
        )}

        {!calculationsLoading ? (
          <Widget
            icon={<IoDocuments className="h-6 w-6" />}
            title={"Total Active Locations"}
            subtitle={
              localStorageData.user.user_role === COMPANY
                ? localStorageData.user.machines.length
                : calculationsData.activeLocations
            }
          />
        ) : (
          <DashboardCardLoader />
        )}

        {localStorageData.user.user_role ===
        COMPANY ? null : !calculationsLoading ? (
          <Widget
            icon={<MdBarChart className="h-7 w-7" />}
            title={"Plastics Saved (kg)"}
            subtitle={Math.ceil(calculationsData.plasticSaved)}
          />
        ) : (
          <DashboardCardLoader />
        )}

        {localStorageData.user.user_role ===
        COMPANY ? null : !calculationsLoading ? (
          <Widget
            icon={<MdDashboard className="h-6 w-6" />}
            title={"Bottle Dispensed"}
            subtitle={calculationsData.bottleDispensed}
          />
        ) : (
          <DashboardCardLoader />
        )}

        {localStorageData.user.user_role ===
        COMPANY ? null : !calculationsLoading ? (
          <Widget
            icon={<MdDashboard className="h-6 w-6" />}
            title={"Napkins Dispenseds"}
            subtitle={calculationsData.napkins}
          />
        ) : (
          <DashboardCardLoader />
        )}
      </div>

      <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-2">
        <BarChart
          barChartDataProps={barChartData}
          totalRevenue={totalRevenue}
          totalTransaction={totalTransactions}
          barChartLoader={barChartLoader}
        />

        <PieChartCard
          value={pieDates}
          handleValueChange={handleValueChange}
          pieRevenue={pieRevenue}
          pieVolume={pieVolume}
          pieTransaction={pieTransaction}
          pieChartLoader={pieChartLoader}
          pieTotal={pieTotal}
          pieChartStats={pieChartStats}
        />
      </div>

      {localStorageData.user.user_role === COMPANY ? null : (
        <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-2">
          <ButterflyLineChart />
          <ButterflyPieChart />
        </div>
      )}
      {localStorageData.user.user_role === COMPANY ? null : (
        <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-2">
          <OilChart />
          <CleaningProductChart />
        </div>
      )}

      {/* machine statics */}
      {localStorageData.user.company_code === "0" ? (
        <div className="mt-5 grid grid-cols-1 gap-5 xl:grid-cols-1">
          {!calculationsLoading ? (
            <MachineStatics tableData={userStatsData} />
          ) : (
            <ProductDetailsLoader path={"dashboard"} />
          )}
        </div>
      ) : null}

      {/* {localStorageData.user.user_role === COMPANY ? null :
        <div className="mt-5 grid grid-cols-1 gap-5 xl:grid-cols-1">
          {!userStaticsLoader ? (
            <UserStaticsTable tableData={userStatsData} />
          ) : (
            <ProductDetailsLoader path={"dashboard"} />
          )}
        </div>
      } */}

      {localStorageData.user.user_role === COMPANY ? null : (
        <div className="mt-5 grid grid-cols-1 gap-5 xl:grid-cols-1">
          <MobileAppUsers />
        </div>
      )}

      {localStorageData.user.user_role === COMPANY ? null : (
        <div className="mt-5 grid grid-cols-1 gap-5 xl:grid-cols-1">
          {!recentTransactionsLoader ? (
            <RecentTransactions
              tableData={recentTransactionsData} // Assuming the API response has a `data` field
              transactionButtons={transactionButtons}
              setTransactionButtons={setTransactionButtons}
              cashAndOnlinePayments={cashAndOnlinePayments}
              setCashAndOnlinePayments={setCashAndOnlinePayments}
              onPageChange={handleChangePage}
              page={currentPage}
            />
          ) : (
            <ProductDetailsLoader path={"dashboard"} />
          )}
        </div>
      )}
    </div>
  );
};

export default Dashboard;
