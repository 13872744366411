import React, { useEffect, useState } from "react";
import moment from "moment-timezone";

export default function UserTransactionTable({
  tableData,
}: {
  tableData: any;
}) {
  console.log(tableData);
  const isStatusAvailable =
    tableData && tableData.length > 0 && "status" in tableData[0];

  const columns = [
    { key: "merchant", label: "User Name" },
    {
      key: "created_at",
      label: "Date",
      format: (val: any) => moment(val).tz("Etc/GMT-0").format("llll"),
    },
    { key: "msisdn", label: "Phone Number / RFID" },
    { key: "machine_code", label: "Machine Code" },
    {
      key: "amount",
      label: "Amount",
      format: (val: any) => Number(val).toLocaleString(),
    },
    ...(isStatusAvailable
      ? [
          {
            key: "status",
            label: "Status",
            format: (val: any) =>
              val === 1 || val === "success" ? (
                <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                  Successful
                </span>
              ) : (
                <span className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/20">
                  Unsuccessful
                </span>
              ),
          },
        ]
      : [
          { key: "quantity", label: "Quantity" },
          { key: "brand_name", label: "Brand Name" },
        ]),
  ];

  console.log(tableData);
  const [isOpen, setIsOpen] = useState(() => window.innerWidth >= 468);

  useEffect(() => {
    const handleResize = () => setIsOpen(window.innerWidth >= 468);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="mb-5 mt-5">
      <div className="flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="ring-black overflow-hidden shadow ring-1 ring-opacity-5 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50 dark:!bg-gray-900">
                  <tr>
                    {columns.map((col) => (
                      <th
                        key={col.key}
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 dark:!text-white sm:pl-6"
                      >
                        {col.label}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-gray-50 dark:!bg-gray-900">
                  {tableData.map((row: any, index: number) => (
                    <tr key={index}>
                      {columns.map((col) => (
                        <td
                          key={col.key}
                          className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 dark:!text-white sm:pl-6"
                        >
                          {col.format
                            ? col.format(row[col.key])
                            : row[col.key] || "-"}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
